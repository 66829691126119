<template>
  <div>
    <div class="e-breadcrumb">员工管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">员工编号:</span>
              <el-input placeholder="员工编号" size="small" style="width:265px" v-model="employeeNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">员工姓名:</span>
              <el-input placeholder="员工姓名" size="small" style="width:265px" v-model="fullName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">性别:</span>
              <el-select size="small" clearable v-model="sex" placeholder="请选择">
                <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>

              <span class="v-form-label">员工状态:</span>
              <el-select size="small" clearable v-model="employeeStatus" placeholder="请选择">
                <el-option
                  v-for="item in employeeStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="margin-right: 37px">
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">员工编号:</th>
                <th width="130" class="th-l">员工姓名</th>
                <th width="130" class="th-l">员工昵称</th>
                <th width="90" class="th-l">性别</th>
                <th width="130" class="th-l">手机</th>
                <th width="130" class="th-l">基本工资</th>
                <th width="100" class="th-l">员工状态</th>
                <th width="130" class="th-l">员工班次</th>
                <th width="100" class="th-l">门店</th>
                <th width="130" class="th-l">入店日期</th>
                <th width="130" class="th-l">创建时间</th>
                <th width="400" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">

                <!--<el-input placeholder="" v-model="createTime" class="handle-input mr10"></el-input>-->

                <td class="td-l"><span>{{row.employeeNo}}</span></td>
                <td class="td-l">{{row.fullName}}</td>
                <td class="td-l">{{row.nickName}}</td>
                <td class="td-l">
                  <span v-if="row.sex==0">男</span>
                  <span v-if="row.sex==1">女</span>
                </td>
                <td class="td-l">{{row.phone}}</td>
                <td class="td-l">{{row.basePay}}</td>
                <td class="td-l" style="width: 100px">
                  <span v-if="row.employeeStatus==0">在职</span>
                  <span v-if="row.employeeStatus==1">离职</span>
                </td>
                <td class="td-l">
                  <span v-if="row.schedules==0">早班</span>
                  <span v-if="row.schedules==1">晚班</span>
                </td>
                <td class="td-l">{{row.storeId}}</td>
                <td class="td-l" style="width: 150px">{{row.joinDate}}</td>
                <td class="td-l" style="width: 150px"><span>{{row.createTime}}</span></td>

                <td class="td-l" style="width: 440px">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="toStation(index)">设置工位</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" v-if="row.isDel==0" @click="removeEvent(index)">删除</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="hfEvent(index)" v-if="row.isDel==1">恢复</el-button>
                </td>

              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiEmployee(1)">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 恢复提示框 -->
    <el-dialog title="提示" :visible.sync="hfVisible" width="300px" center>
      <div class="del-dialog-cnt">是否恢复此员工？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="hfVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiEmployee(0)">确 定</el-button>
            </span>
  </el-dialog>

    <!-- 设置部门 -->
    <el-dialog title="设置部门" :visible.sync="setVisible" width="500px" center>
      <div>
        <span class="v-form-label">部门:</span>
        <el-cascader size="small"
                     v-model="departmentIds"
                     style="width: 300px" :options="options">
        </el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="setVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setDepartment">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 设置工位 -->
    <el-dialog title="设置工位" :visible.sync="stationVisible" width="500px" center>
      <div>
        <el-checkbox-group v-model="categoryIds">
          <el-checkbox v-for="item in categoryList" :label="item.id" :key="item.id">{{item.categoryName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="stationVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveStation">确 定</el-button>
            </span>
    </el-dialog>

  </div>


</template>

<script>

  export default {
    data() {
      return {
        departmentIds: [],
        employeeNo: '',
        fullName: '',
        sex: '',
        employeeStatus: 0,

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        setVisible: false,
        delVisible: false,
        hfVisible: false,
        editItem: {},

        sexList: [{
          value: 0,
          label: '男'
        }, {
          value: 1,
          label: '女'
        }],

        appointmentList: [{
          value: 0,
          label: '不可预约'
        }, {
          value: 1,
          label: '可预约'
        }],

        schedulesList: [{
          value: 0,
          label: '早班'
        }, {
          value: 1,
          label: '晚班'
        }],
        employeeStatusList: [{
          value: 0,
          label: '在职'
        }, {
          value: 1,
          label: '离职'
        }],
        options: [],

        stationVisible: false,
        categoryList: [],
        categoryIds: [],
      }
    },
    async created() {
      this.searchEvent();
      this.getDepartmens()
      this.getCategoryList()
    },
    methods: {

      async getCategoryList() {
        let data = {
          categoryType: '3'
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },

      toStation(index) {
        this.editItem = this.list[index]
        this.categoryIds = this.list[index].categoryIds
        this.stationVisible = true
      },

      async saveStation() {
        if (this.categoryIds.length < 0) {
          this.$message.error("请选择工位")
          return;
        }
        if (this.categoryIds.length > 1) {
          this.$message.error("只能选择一个工位")
          return;
        }
        let data = {
          employeeId: this.editItem.id,
          categoryIds: JSON.stringify(this.categoryIds)
        }
        let res = await this.$post("/admin/saveStation", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.searchEvent()
          this.stationVisible = false
        } else {
          this.$message.error(res.message)
        }
      },

      async setDepartment() {
        if (this.departmentIds.length != 2) {
          this.$message.error("请选择部门")
          return;
        }
        let data = {
          id: this.editItem.id,
          departmentId: this.departmentIds[this.departmentIds.length - 1]
        }
        let res = await this.$post("/admin/update_bbiEmployee", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.searchEvent()
          this.setVisible = false
        } else {
          this.$message.error(res.message)
        }
      },


      toSetDepartment(index) {
        this.editItem = this.list[index]
        if (this.list[index].departmentId != null && this.list[index].departmentId != '' && this.list[index].parentId != null && this.list[index].parentId != '') {
          const arr = []
          arr.push(this.list[index].parentId)
          arr.push(this.list[index].departmentId)
          this.departmentIds = arr
        } else {
          this.departmentIds = []
        }
        this.setVisible = true
      },
      async getDepartmens() {
        let res = await this.$get("/admin/getDepartmens")
        res.forEach(item => {
          let children = []
          item.sysDepartmentList.forEach(sd => {
            let i = {
              value: sd.id,
              label: sd.departmentName,
            }
            children.push(i)
          })
          let it = {
            value: item.id,
            label: item.departmentName,
            children: children
          }
          this.options.push(it)
        })
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {

        let currentPage = page || 1;
        let data = {
          employeeNo: this.employeeNo,
          fullName: this.fullName,
          nickName: this.nickName,
          employeeStatus:this.employeeStatus,
          sex: this.sex,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBbiEmployeeForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },


      addEvent() {
        this.$router.push("/employees/saveEmployee")
      },

      editEvent(index) {
        this.$router.push("/employees/updateEmployee?id=" + this.list[index].id)
      },


      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },
      async hfEvent(index) {
        this.editItem = this.list[index]
        this.hfVisible = true;
      },

      //删除BbiEmployee
      async delBbiEmployee(type) {
        let data = {
          id: this.editItem.id,
          isDel:type
        };
        let res = await this.$post("/admin/delete_bbiEmployee", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
          this.hfVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
